import { Component } from '@angular/core';
import { Platform, MenuController, ModalController, LoadingController } from '@ionic/angular';
import { Events } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { Globalization } from '@ionic-native/globalization/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { CartComponent } from './cart/cart.component';
import { ApiService } from './services/api.service';
import { trigger, style, animate, transition, state } from '@angular/animations';
import { interval } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  lang;
  content;
  dates = [];
  loading;
  appPages = [];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private apiService: ApiService,
    private menu: MenuController,
    private globalization: Globalization,
    public loadingController: LoadingController,
    private modal: ModalController,
    public events: Events
  ) {
    this.initializeApp();
  }

    async presentLoading(type, category, id) {
      this.loading = await this.loadingController.create({
      spinner: 'dots',
      });
      await this.loading.present();
      if(type!='') {
        setTimeout(() => { this.openModal(type, category, id); }, 1)
      }
    }

    async openModal(type, category, id) {
      let globalClass_ET = document.getElementsByClassName("_showLogo_3q __jivoMobileButton") as HTMLCollectionOf<HTMLElement>;
      globalClass_ET[0].style['transition-property'] = 'all';
      globalClass_ET[0].style['transition-duration'] = '0.5s';
      globalClass_ET[0].style['opacity'] = '0';
      globalClass_ET[0].style['bottom'] = '-100px';

      const modal = await this.modal.create({
        component: CartComponent,
        componentProps: { type: type, category: category, id: id }
      });

      modal.onDidDismiss()
        .then((data) => {
          if (data['data']) {
            this.apiService.setDates(data['data']);
          }
        });

      return await modal.present().then((data) => {
        this.loading.dismiss();
      });

    }


  closeMenu() {
    this.menu.close();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.apiService.setCalendar();
      this.apiService.apiCatalog('GetCatalog');
      this.apiService.getToken();

      let w8 = interval(100).subscribe(x => {
        this.content = this.apiService.getContent();
        if (this.content) {
          let menu = this.content.filter(x => x.page === 'menu')[0].data;

          this.appPages = [
            {
              title: menu[0],
              url: '/tabs/catalog',
              icon: 'bed'
            },
            {
              title: menu[1],
              url: '/tabs/history',
              icon: 'filing'
            },
            {
              title: menu[2],
              url: '/tabs/profile',
              icon: 'contact'
            },
            {
              title: menu[3],
              url: '/tabs/about',
              icon: 'ribbon'
            },
            {
              title: menu[4],
              url: '/tabs/contacts',
              icon: 'map'
            }
          ];
          w8.unsubscribe();
        }
      });

      var jivosite = document.createElement("script");
      jivosite.setAttribute("type", "text/javascript");
      jivosite.setAttribute("async", "true");
      jivosite.setAttribute("src", "//code.jivosite.com/script/widget/1bMsVlbekW");
      document.head.appendChild(jivosite);

      setTimeout(() => { this.splashScreen.hide(); }, 300)

    });
  }


}
