import { Component, OnInit, LOCALE_ID } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Events, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { ApiService } from '../services/api.service';
import { trigger, style, animate, transition } from '@angular/animations';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import localeEn from '@angular/common/locales/en';
import { formatDate } from '@angular/common';
import { interval } from 'rxjs';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.html',
  styleUrls: ['./cart.scss'],
  providers: [
    { provide: LOCALE_ID, useValue: "ru-RU" }
  ],
  animations: [
    trigger(
      'BottomToTop', [
        transition(':enter', [
          style({ transform: 'translatey(50%)', opacity: 0 }),
          animate('0.5s ease-in-out', style({ transform: 'translatey(0%)', opacity: 1 }))
        ]),
        transition(':leave', [
          style({ transform: 'translatey(0%)', opacity: 1 }),
          animate('0.5s ease-in-out', style({ transform: 'translatey(50%)', opacity: 0 }))
        ])
      ]
    ),
    trigger(
      'FadeIn', [
        transition(':enter', [
          style({ transform: 'translatey(100%)', opacity: 0 }),
          animate('0.5s ease-in-out', style({ transform: 'translatey(0%)', opacity: 1 }))
        ]),
        transition(':leave', [
          style({ transform: 'translatey(0%)', opacity: 1 }),
          animate('0.5s ease-in-out', style({ transform: 'translatey(100%)', opacity: 0 }))
        ])
      ]
    )

  ]
})
export class CartComponent implements OnInit {

  category;
  id;
  scrollTop = 0;
  calendarDone;
  calendarBtnsDone;
  type;
  content;
  fromText = 'Check in';
  toText = 'Check out';
  calendarData = [];
  allDates = [];
  preYearCalendarData = [];
  weekdays = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'];
  booking = [{ from: null, to: null, free: [], selected: [], fullPrice: 0 }];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modal: ModalController,
    private apiService: ApiService,
    private storage: Storage,
    public events: Events
  ) {


  }

  getBooking(row) {
    setTimeout(() => {
      if (row.s != 2) {

        if (this.booking[0].from && this.booking[0].from != row.date) {
          if (this.booking[0].to) {
            this.booking[0].from = row.date;
            this.booking[0].to = null;
            let i = 0;
            this.booking[0].free = [];
            this.allDates.forEach(item => {
              if (item.date >= row.date) {
                if (i == 0) {
                  if (item.s == 2) {
                    i += 1;
                  }
                  else {
                    this.booking[0].free.push(item.date);
                  }
                }
              }
            });
            if (this.booking[0].free.length == 1) {
              this.booking[0].to = this.booking[0].from;
            }
          }
          else {
            this.booking[0].to = row.date;
            this.booking[0].free = [];
          }
        }
        else {

          if (this.booking[0].to) {
            this.booking[0].to = null;
            this.booking[0].free = [];
          }
          else {
            this.booking[0].from = row.date;

            let i = 0;
            this.booking[0].free = [];
            this.allDates.forEach(item => {
              if (item.date >= row.date) {
                if (i == 0) {
                  if (item.s == 2) {
                    i += 1;
                  }
                  else {
                    this.booking[0].free.push(item.date);
                  }
                }
              }
            });
            if (this.booking[0].free.length == 1) {
              this.booking[0].to = this.booking[0].from;
            }
          }
        }

        if (this.booking[0].from && this.booking[0].to) {
          let i = 0;
          this.booking[0].selected = [];
          this.booking[0].fullPrice = 0;
          this.allDates.forEach(item => {
            if (item.date >= this.booking[0].from && item.date <= this.booking[0].to) {
              this.booking[0].fullPrice += Number(item.o);
              this.booking[0].selected.push(item.date);
            }
          });
        }
        else {
          this.booking[0].selected = [];
        }

      }

    }, 100)
  }

  selectedCheck(date) {
    return this.booking[0].selected.find(x => x == date);
  }

  freeCheck(date) {
    return this.booking[0].free.find(x => x == date);
  }

  closeModal(type) {

    if (type != '' && this.booking[0].to) {
      this.storage.set('booking', this.booking);
      this.apiService.setDates(this.booking);
      this.events.publish('changeDates');
      this.modal.dismiss(this.booking);
      if(this.type=='booking') {
        this.router.navigate(['/tabs/catalog/'+this.category+'/'+this.id+'/booking/']);
      }
    }
    else {
      this.modal.dismiss();
    }

    let globalClass_ET = document.getElementsByClassName("_showLogo_3q __jivoMobileButton") as HTMLCollectionOf<HTMLElement>;
    globalClass_ET[0].style['opacity'] = '1';
    globalClass_ET[0].style['transition-property'] = 'all';
    globalClass_ET[0].style['transition-duration'] = '0.5s';

    globalClass_ET[0].style['bottom'] = '0px';

  }

  ngOnInit() {

    if (this.apiService.getLang() == 'ru-RU') {
      registerLocaleData(localeRu, 'ru');
      this.weekdays = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'];
    }
    else {
      registerLocaleData(localeEn, 'en');
      this.weekdays = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'];
    }
    this.fromText = this.apiService.getContent().filter(x => x.page === 'all')[0].data[0];
    this.toText = this.apiService.getContent().filter(x => x.page === 'all')[0].data[1];

    this.content = this.apiService.getContent().filter(x => x.page === 'calendar')[0].data;


    this.preYearCalendarData = this.apiService.getCalendar()[0].preYearCalendarData; this.calendarData = this.apiService.getCalendar()[0].calendarData;
    this.allDates = this.apiService.getCalendar()[0].allDates;
    if (this.calendarData) {
      if (this.calendarData.length > 0) {
        if (this.type == 'booking') {
          this.calendarData.forEach(row => {
            if (row.data.length > 0) {
              row.data.forEach(item => {
                if (this.allDates.find(x => x.date == item.date)) {
                  this.allDates.find(x => x.date == item.date).o = 1500;
                }
                item.o = 1500;
                if (item.date) {
                  // if (formatDate(item.date, 'd LLLL yyyy', 'ru') == '21 май 2019') { item.s = 2; }
                }

              });
            }
          });
          this.calendarDone = true;
        }
        else {
          this.calendarDone = true;
        }


        this.storage.get('booking').then((val) => {
          if (val) {
            this.booking = val;
            if (this.booking[0].selected.length > 0) {
              let i = 0;
              this.booking[0].selected = [];
              this.booking[0].fullPrice = 0;
              this.allDates.forEach(item => {
                if (item.date >= this.booking[0].from && item.date <= this.booking[0].to) {
                  this.booking[0].fullPrice += Number(item.o);
                  this.booking[0].selected.push(item.date);
                }
              });
            }
          }
        });

        if (this.type == 'booking') {
          this.calendarBtnsDone = true;
        }
        else {
          this.booking[0].fullPrice = 0;
          this.calendarBtnsDone = true;
        }

      }
    }


  }


}
