import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { Globalization } from '@ionic-native/globalization/ngx';
import { Observable, Subject, asapScheduler, pipe, of, from, interval, merge, fromEvent, throwError } from 'rxjs';
import { catchError, retry, map, filter, scan } from 'rxjs/operators';

import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import localeEn from '@angular/common/locales/en';
import { formatDate } from '@angular/common';

@Injectable()
export class ApiService {

  date;
  fullDate;
  preCalendarData = [];
  preYearCalendarData = [];
  calendarData = [];
  allDates = [];
  calendar = [];
  weekdays = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'];
  clientData;
  langTitle = 'en';
  user_id;
  lang;
  dates;
  catalog;
  history;
  content;
  token = 'pzKCbjZhDcISCfS6hLw3pXsbIVjaXghvJBK4ZLA9rglHbczEq3VJWsGKgLRf8g6gi6vq62BYEi3coXrxCMQbFiYFPF';

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + this.token
    })
  }
  httpOptionsApi = {
    headers: new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  }


  private handleError(error: HttpErrorResponse) {
    return throwError('Something get wrong');
  };

  constructor(
    private httpClient: HttpClient,
    private storage: Storage,
    private globalization: Globalization,
  ) {

    this.globalization.getPreferredLanguage()
      .then(res => this.lang = res.value)
      .catch();
    if (!this.lang) {
      this.lang = window.navigator.language;
    }

    this.storage.get('user_id').then((val) => {
      if (val) {
        this.user_id = val;
      }
    });

    this.storage.get('clientData').then((val) => {
      if (val) {
        this.clientData = val;
      }
    });

    this.storage.get('booking').then((val) => {
      if (val) {
        this.dates = val;
      }
    });

  }

  getCatalog() {
    return this.catalog;
  }
  getContent() {
    return this.content;
  }
  getHistory() {
    return this.history;
  }
  pushHistory(history) {
    console.log(history);
    this.history.push(history[0]);
  }

  getclientData() {
    return this.clientData;
  }
  setclientData(data) {
    this.storage.set('clientData', data);
    this.clientData = data;
  }

  getLang() {
    return this.lang;
  }

  setDates(dates) {
    this.dates = dates;
  }
  getDates() {
    if (this.dates) {
      return this.dates;
    }
    else {
      return [];
    }
  }
  setUserId(id) {
    this.storage.set('user_id', id);
    this.user_id = id;
  }
  getUserId() {
    return this.user_id;
  }


  apiCatalog(type) {
    this.storage.get('user_id').then((val) => {
      let user = '';
      if (val) {
        this.user_id = val;
          user = '&user_id=' + this.user_id
        }
        this.httpClient
          .get('https://api.anking.ru/hotels/' + type + '?lang=' + this.lang + user, this.httpOptions)
          .pipe(catchError(this.handleError))
          .subscribe((Response) => {
            this.content = Response[0].content;
            this.history = Response[0].history;
            this.content.push({ page: 'countries', data: Response[0].countries });
            this.catalog = Response[0].catalog;
          });
    });
  }

  setHistory(formData) {
    return this.httpClient
      .post('https://api.anking.ru/hotels/AddHistory', formData, this.httpOptions)
      .pipe(catchError(this.handleError))
  }



  getToken() {
    let formData = { username: 'anking@mail.com', password : 'bMSVaXjySZKE74GfjjNLMExnHcLwKis8' };

    this.httpClient
      .post("https://api.reservationsteps.ru/v1/api/auth", 'username=anking@mail.com&password=bMSVaXjySZKE74GfjjNLMExnHcLwKis8', this.httpOptionsApi)
      .pipe(catchError(this.handleError))
      .subscribe((Response) => {
        console.log(Response);
      });
  }

  getCalendar() {
    return this.calendar;
  }

  setCalendar() {

    if (this.lang == 'ru-RU') {
      registerLocaleData(localeRu, 'ru');
      this.langTitle = 'ru';
    }
    else {
      registerLocaleData(localeEn, 'en');
      this.langTitle = 'en';
    }

    this.date = new Date();
    this.fullDate = new Date();
    let sumDays = 90;

    let i = 0;
    do {
      i = i + 1;

      let y = formatDate(this.date, 'yyyy', this.langTitle);
      let m = formatDate(this.date, 'LLLL', this.langTitle);
      let w = formatDate(this.date, 'EEE', this.langTitle);
      let d = formatDate(this.date, 'd', this.langTitle);
      let s = 1;
      let fullDate = this.date.getTime();

      this.preCalendarData.push({ y: y, m: m, w: w, d: d, date: fullDate, s: s })
      this.date.setDate(this.date.getDate() + 1);


      if (!this.preYearCalendarData.find(x => x.y == y)) {
        this.preYearCalendarData.push({ y: y, data: [] });
      }

    } while (i < sumDays);

    this.calendarData = [
      { y: 0, m: 'январь', data: [] },
      { y: 0, m: 'февраль', data: [] },
      { y: 0, m: 'март', data: [] },
      { y: 0, m: 'апрель', data: [] },
      { y: 0, m: 'май', data: [] },
      { y: 0, m: 'июнь', data: [] },
      { y: 0, m: 'июль', data: [] },
      { y: 0, m: 'август', data: [] },
      { y: 0, m: 'сентябрь', data: [] },
      { y: 0, m: 'октябрь', data: [] },
      { y: 0, m: 'ноябрь', data: [] },
      { y: 0, m: 'декабрь', data: [] }
    ];

    this.preCalendarData.forEach(data => {
      this.calendarData.forEach(item => {
        if (item.m == data.m) {
          item.data.push({ o: 0, y: data.y, w: data.w, d: data.d, date: data.date, s: data.s });
          item.y = data.y;
          this.allDates.push({ o: 0, y: data.y, w: data.w, d: data.d, date: data.date, s: data.s });
        }
      });
    });

    this.calendarData.forEach(row => {
      if (row.data.length > 0) {

        let wcount = 0;
        let ic = 0;
        let iv = 0;
        row.data.forEach(item => {
          ic = ic + 1;
          if (ic == 1) {
            if (item.w == 'пн') {
              wcount = 0;
            }
            if (item.w == 'вт') {
              wcount = 1;
            }
            if (item.w == 'ср') {
              wcount = 2;
            }
            if (item.w == 'чт') {
              wcount = 3;
            }
            if (item.w == 'пт') {
              wcount = 4;
            }
            if (item.w == 'сб') {
              wcount = 5;
            }
            if (item.w == 'вс') {
              wcount = 6;
            }
          }
        });
        if (wcount > 0) {
          do {
            if (iv == 0) {
              row.data.push({ w: 'пн', d: 0 });
            }
            if (iv == 1) {
              row.data.push({ w: 'вт', d: 0 });
            }
            if (iv == 2) {
              row.data.push({ w: 'ср', d: 0 });
            }
            if (iv == 3) {
              row.data.push({ w: 'чт', d: 0 });
            }
            if (iv == 4) {
              row.data.push({ w: 'пт', d: 0 });
            }
            if (iv == 5) {
              row.data.push({ w: 'сб', d: 0 });
            }
            if (iv == 6) {
              row.data.push({ w: 'вс', d: 0 });
            }

            iv = iv + 1;
          } while (iv < wcount);
        }
      }

    });

    this.calendar = [{ preYearCalendarData: this.preYearCalendarData, calendarData: this.calendarData, allDates: this.allDates }];

  }


}
